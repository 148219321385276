import { IndexedDbRowId } from '@astrion-webtools/graph';
import { AStrionSignalId } from '@features/signals/shared/interface/astrion-signal.interface';
import { createReducer, on } from '@ngrx/store';
import { loaded, LoadingState } from '@shared/interfaces/loading-state';

import { DataValidation } from '../interface/data-validation';
import {
  defaultFundamentalPeriodicityTestWithLoadingState,
  FundamentalPeriodicityTest,
} from '../interface/fundamental-periodicity';
import { defaultSamplingTestWithLoadingState, ShannonTest } from '../interface/sampling-test';
import { defaultStationarityTestWithLoadingState, Stationarity } from '../interface/stationarity';
import { defaultSaturationTestWithLoadingState, TimeSaturation } from '../interface/time-saturation';
import { DataValidationActions } from './data-validation.actions';
import { DATA_VALIDATION_INITIAL_STATE, DataValidationState } from './data-validation.state';

export const reducer = createReducer(
  DATA_VALIDATION_INITIAL_STATE,
  on(
    DataValidationActions.dataValidationReset,
    (state: DataValidationState, { signalId }: { signalId: AStrionSignalId | null }): DataValidationState => ({
      ...DATA_VALIDATION_INITIAL_STATE,
      signalId,
    })
  ),
  on(
    DataValidationActions.dataValidationFetchRequested,
    (state: DataValidationState, { signalId }: { signalId: AStrionSignalId }): DataValidationState => {
      if (signalId !== state.signalId) {
        return state;
      }

      return {
        ...DATA_VALIDATION_INITIAL_STATE,
        signalId,
        loadingState: LoadingState.Loading,
      };
    }
  ),
  on(
    DataValidationActions.dataValidationFetched,
    (
      state: DataValidationState,
      { signalId, dataValidation }: { signalId: AStrionSignalId; dataValidation: DataValidation }
    ): DataValidationState => {
      if (signalId !== state.signalId) {
        return state;
      }

      return {
        ...state,
        ...dataValidation,
      };
    }
  ),
  on(
    DataValidationActions.dataValidationNotFound,
    (state: DataValidationState, { signalId }: { signalId: AStrionSignalId }): DataValidationState => {
      if (signalId !== state.signalId) {
        return state;
      }

      return {
        ...DATA_VALIDATION_INITIAL_STATE,
        loadingState: LoadingState.NotFound,
      };
    }
  ),
  on(
    DataValidationActions.dataValidationFetchFailed,
    (state: DataValidationState, { signalId }: { signalId: AStrionSignalId }): DataValidationState => {
      if (signalId !== state.signalId) {
        return state;
      }

      return {
        ...DATA_VALIDATION_INITIAL_STATE,
        loadingState: LoadingState.Error,
      };
    }
  ),
  on(
    DataValidationActions.timeSaturationTestFetchRequested,
    (state: DataValidationState, { signalId }: { signalId: AStrionSignalId }): DataValidationState => {
      if (signalId !== state.signalId || !loaded(state)) {
        return state;
      }

      return {
        ...state,
        saturationTest: defaultSaturationTestWithLoadingState(LoadingState.Loading),
      };
    }
  ),
  on(
    DataValidationActions.timeSaturationTestFetched,
    (
      state: DataValidationState,
      { signalId, saturationTest }: { signalId: AStrionSignalId; saturationTest: TimeSaturation }
    ): DataValidationState => {
      if (signalId !== state.signalId || !loaded(state)) {
        return state;
      }

      return {
        ...state,
        saturationTest,
      };
    }
  ),
  on(
    DataValidationActions.timeSaturationTestNotFound,
    (state: DataValidationState, { signalId }: { signalId: AStrionSignalId }): DataValidationState => {
      if (signalId !== state.signalId || !loaded(state)) {
        return state;
      }

      return {
        ...state,
        saturationTest: defaultSaturationTestWithLoadingState(LoadingState.NotFound),
      };
    }
  ),
  on(
    DataValidationActions.timeSaturationTestFetchFailed,
    (state: DataValidationState, { signalId }: { signalId: AStrionSignalId }): DataValidationState => {
      if (signalId !== state.signalId || !loaded(state)) {
        return state;
      }

      return {
        ...state,
        saturationTest: defaultSaturationTestWithLoadingState(LoadingState.Error),
      };
    }
  ),
  on(
    DataValidationActions.samplingTestFetchRequested,
    (state: DataValidationState, { signalId }: { signalId: AStrionSignalId }): DataValidationState => {
      if (signalId !== state.signalId || !loaded(state)) {
        return state;
      }

      return {
        ...state,
        samplingTest: defaultSamplingTestWithLoadingState(LoadingState.Loading),
      };
    }
  ),
  on(
    DataValidationActions.samplingTestFetched,
    (
      state: DataValidationState,
      { signalId, samplingTest }: { signalId: AStrionSignalId; samplingTest: ShannonTest }
    ): DataValidationState => {
      if (signalId !== state.signalId || !loaded(state)) {
        return state;
      }

      return {
        ...state,
        samplingTest,
      };
    }
  ),
  on(
    DataValidationActions.samplingTestNotFound,
    (state: DataValidationState, { signalId }: { signalId: AStrionSignalId }): DataValidationState => {
      if (signalId !== state.signalId || !loaded(state)) {
        return state;
      }

      return {
        ...state,
        samplingTest: defaultSamplingTestWithLoadingState(LoadingState.NotFound),
      };
    }
  ),
  on(
    DataValidationActions.samplingTestFetchFailed,
    (state: DataValidationState, { signalId }: { signalId: AStrionSignalId }): DataValidationState => {
      if (signalId !== state.signalId || !loaded(state)) {
        return state;
      }

      return {
        ...state,
        samplingTest: defaultSamplingTestWithLoadingState(LoadingState.Error),
      };
    }
  ),
  on(
    DataValidationActions.samplingTestLinearBytesFetchRequested,
    (state: DataValidationState, { signalId }: { signalId: AStrionSignalId }): DataValidationState => {
      if (signalId !== state.signalId || !loaded(state) || !loaded(state.samplingTest)) {
        return state;
      }

      return {
        ...state,
        samplingTest: {
          ...state.samplingTest,
          data: {
            ...state.samplingTest.data!,
            linearTestSummary: {
              ...state.samplingTest.data!.linearTestSummary,
              spectrumAmplitudes: {
                ...state.samplingTest.data!.linearTestSummary.spectrumAmplitudes,
                loadingState: LoadingState.Loading,
                data: undefined,
              },
            },
          },
        },
      };
    }
  ),
  on(
    DataValidationActions.samplingTestLinearBytesFetched,
    (
      state: DataValidationState,
      { signalId, dataId }: { signalId: AStrionSignalId; dataId: IndexedDbRowId }
    ): DataValidationState => {
      if (signalId !== state.signalId || !loaded(state) || !loaded(state.samplingTest)) {
        return state;
      }

      return {
        ...state,
        samplingTest: {
          ...state.samplingTest,
          data: {
            ...state.samplingTest.data!,
            linearTestSummary: {
              ...state.samplingTest.data!.linearTestSummary,
              spectrumAmplitudes: {
                ...state.samplingTest.data!.linearTestSummary.spectrumAmplitudes,
                loadingState: LoadingState.Loaded,
                data: dataId,
              },
            },
          },
        },
      };
    }
  ),
  on(
    DataValidationActions.samplingTestLinearBytesNotFound,
    (state: DataValidationState, { signalId }: { signalId: AStrionSignalId }): DataValidationState => {
      if (signalId !== state.signalId || !loaded(state) || !loaded(state.samplingTest)) {
        return state;
      }

      return {
        ...state,
        samplingTest: {
          ...state.samplingTest,
          data: {
            ...state.samplingTest.data!,
            linearTestSummary: {
              ...state.samplingTest.data!.linearTestSummary,
              spectrumAmplitudes: {
                ...state.samplingTest.data!.linearTestSummary.spectrumAmplitudes,
                loadingState: LoadingState.NotFound,
                data: undefined,
              },
            },
          },
        },
      };
    }
  ),
  on(
    DataValidationActions.samplingTestLinearBytesFetchFailed,
    (state: DataValidationState, { signalId }: { signalId: AStrionSignalId }): DataValidationState => {
      if (signalId !== state.signalId || !loaded(state) || !loaded(state.samplingTest)) {
        return state;
      }

      return {
        ...state,
        samplingTest: {
          ...state.samplingTest,
          data: {
            ...state.samplingTest.data!,
            linearTestSummary: {
              ...state.samplingTest.data!.linearTestSummary,
              spectrumAmplitudes: {
                ...state.samplingTest.data!.linearTestSummary.spectrumAmplitudes,
                loadingState: LoadingState.Error,
                data: undefined,
              },
            },
          },
        },
      };
    }
  ),
  on(
    DataValidationActions.samplingTestdBBytesFetchRequested,
    (state: DataValidationState, { signalId }: { signalId: AStrionSignalId }): DataValidationState => {
      if (signalId !== state.signalId || !loaded(state) || !loaded(state.samplingTest)) {
        return state;
      }

      return {
        ...state,
        samplingTest: {
          ...state.samplingTest,
          data: {
            ...state.samplingTest.data!,
            dbTestSummary: {
              ...state.samplingTest.data!.dbTestSummary,
              spectrumAmplitudes: {
                ...state.samplingTest.data!.dbTestSummary.spectrumAmplitudes,
                loadingState: LoadingState.Loading,
                data: undefined,
              },
            },
          },
        },
      };
    }
  ),
  on(
    DataValidationActions.samplingTestdBBytesFetched,
    (
      state: DataValidationState,
      { signalId, dataId }: { signalId: AStrionSignalId; dataId: IndexedDbRowId }
    ): DataValidationState => {
      if (signalId !== state.signalId || !loaded(state) || !loaded(state.samplingTest)) {
        return state;
      }

      return {
        ...state,
        samplingTest: {
          ...state.samplingTest,
          data: {
            ...state.samplingTest.data!,
            dbTestSummary: {
              ...state.samplingTest.data!.dbTestSummary,
              spectrumAmplitudes: {
                ...state.samplingTest.data!.dbTestSummary.spectrumAmplitudes,
                loadingState: LoadingState.Loaded,
                data: dataId,
              },
            },
          },
        },
      };
    }
  ),
  on(
    DataValidationActions.samplingTestdBBytesNotFound,
    (state: DataValidationState, { signalId }: { signalId: AStrionSignalId }): DataValidationState => {
      if (signalId !== state.signalId || !loaded(state) || !loaded(state.samplingTest)) {
        return state;
      }

      return {
        ...state,
        samplingTest: {
          ...state.samplingTest,
          data: {
            ...state.samplingTest.data!,
            dbTestSummary: {
              ...state.samplingTest.data!.dbTestSummary,
              spectrumAmplitudes: {
                ...state.samplingTest.data!.dbTestSummary.spectrumAmplitudes,
                loadingState: LoadingState.NotFound,
                data: undefined,
              },
            },
          },
        },
      };
    }
  ),
  on(
    DataValidationActions.samplingTestdBBytesFetchFailed,
    (state: DataValidationState, { signalId }: { signalId: AStrionSignalId }): DataValidationState => {
      if (signalId !== state.signalId || !loaded(state) || !loaded(state.samplingTest)) {
        return state;
      }

      return {
        ...state,
        samplingTest: {
          ...state.samplingTest,
          data: {
            ...state.samplingTest.data!,
            dbTestSummary: {
              ...state.samplingTest.data!.dbTestSummary,
              spectrumAmplitudes: {
                ...state.samplingTest.data!.dbTestSummary.spectrumAmplitudes,
                loadingState: LoadingState.Error,
                data: undefined,
              },
            },
          },
        },
      };
    }
  ),
  on(
    DataValidationActions.samplingTestNoiseBytesFetchRequested,
    (state: DataValidationState, { signalId }: { signalId: AStrionSignalId }): DataValidationState => {
      if (signalId !== state.signalId || !loaded(state) || !loaded(state.samplingTest)) {
        return state;
      }

      return {
        ...state,
        samplingTest: {
          ...state.samplingTest,
          data: {
            ...state.samplingTest.data!,
            noiseTestSummary: {
              ...state.samplingTest.data!.noiseTestSummary,
              spectrumAmplitudes: {
                ...state.samplingTest.data!.noiseTestSummary.spectrumAmplitudes,
                loadingState: LoadingState.Loading,
                data: undefined,
              },
            },
          },
        },
      };
    }
  ),
  on(
    DataValidationActions.samplingTestNoiseBytesFetched,
    (
      state: DataValidationState,
      { signalId, dataId }: { signalId: AStrionSignalId; dataId: IndexedDbRowId }
    ): DataValidationState => {
      if (signalId !== state.signalId || !loaded(state) || !loaded(state.samplingTest)) {
        return state;
      }

      return {
        ...state,
        samplingTest: {
          ...state.samplingTest,
          data: {
            ...state.samplingTest.data!,
            noiseTestSummary: {
              ...state.samplingTest.data!.noiseTestSummary,
              spectrumAmplitudes: {
                ...state.samplingTest.data!.noiseTestSummary.spectrumAmplitudes,
                loadingState: LoadingState.Loaded,
                data: dataId,
              },
            },
          },
        },
      };
    }
  ),
  on(
    DataValidationActions.samplingTestNoiseBytesNotFound,
    (state: DataValidationState, { signalId }: { signalId: AStrionSignalId }): DataValidationState => {
      if (signalId !== state.signalId || !loaded(state) || !loaded(state.samplingTest)) {
        return state;
      }

      return {
        ...state,
        samplingTest: {
          ...state.samplingTest,
          data: {
            ...state.samplingTest.data!,
            noiseTestSummary: {
              ...state.samplingTest.data!.noiseTestSummary,
              spectrumAmplitudes: {
                ...state.samplingTest.data!.noiseTestSummary.spectrumAmplitudes,
                loadingState: LoadingState.NotFound,
                data: undefined,
              },
            },
          },
        },
      };
    }
  ),
  on(
    DataValidationActions.samplingTestNoiseBytesFetchFailed,
    (state: DataValidationState, { signalId }: { signalId: AStrionSignalId }): DataValidationState => {
      if (signalId !== state.signalId || !loaded(state) || !loaded(state.samplingTest)) {
        return state;
      }

      return {
        ...state,
        samplingTest: {
          ...state.samplingTest,
          data: {
            ...state.samplingTest.data!,
            noiseTestSummary: {
              ...state.samplingTest.data!.noiseTestSummary,
              spectrumAmplitudes: {
                ...state.samplingTest.data!.noiseTestSummary.spectrumAmplitudes,
                loadingState: LoadingState.Error,
                data: undefined,
              },
            },
          },
        },
      };
    }
  ),
  on(
    DataValidationActions.stationarityTestFetchRequested,
    (state: DataValidationState, { signalId }: { signalId: AStrionSignalId }): DataValidationState => {
      if (signalId !== state.signalId || !loaded(state)) {
        return state;
      }

      return {
        ...state,
        stationarityTest: defaultStationarityTestWithLoadingState(LoadingState.Loading),
      };
    }
  ),
  on(
    DataValidationActions.stationarityTestFetched,
    (
      state: DataValidationState,
      { signalId, stationarityTest }: { signalId: AStrionSignalId; stationarityTest: Stationarity }
    ): DataValidationState => {
      if (signalId !== state.signalId || !loaded(state)) {
        return state;
      }

      return {
        ...state,
        stationarityTest,
      };
    }
  ),
  on(
    DataValidationActions.stationarityTestNotFound,
    (state: DataValidationState, { signalId }: { signalId: AStrionSignalId }): DataValidationState => {
      if (signalId !== state.signalId || !loaded(state)) {
        return state;
      }

      return {
        ...state,
        stationarityTest: defaultStationarityTestWithLoadingState(LoadingState.NotFound),
      };
    }
  ),
  on(
    DataValidationActions.stationarityTestFetchFailed,
    (state: DataValidationState, { signalId }: { signalId: AStrionSignalId }): DataValidationState => {
      if (signalId !== state.signalId || !loaded(state)) {
        return state;
      }

      return {
        ...state,
        stationarityTest: defaultStationarityTestWithLoadingState(LoadingState.Error),
      };
    }
  ),
  on(
    DataValidationActions.signalFundamentalPeriodicityTestFetchRequested,
    (state: DataValidationState, { signalId }: { signalId: AStrionSignalId }): DataValidationState => {
      if (signalId !== state.signalId || !loaded(state)) {
        return state;
      }

      return {
        ...state,
        fundamentalPeriodicityTest: defaultFundamentalPeriodicityTestWithLoadingState(LoadingState.Loading),
      };
    }
  ),
  on(
    DataValidationActions.signalFundamentalPeriodicityTestFetched,
    (
      state: DataValidationState,
      { signalId, periodicity }: { signalId: AStrionSignalId; periodicity: FundamentalPeriodicityTest }
    ): DataValidationState => {
      if (signalId !== state.signalId || !loaded(state)) {
        return state;
      }

      return {
        ...state,
        fundamentalPeriodicityTest: periodicity,
      };
    }
  ),
  on(
    DataValidationActions.signalFundamentalPeriodicityTestNotFound,
    (state: DataValidationState, { signalId }: { signalId: AStrionSignalId }): DataValidationState => {
      if (signalId !== state.signalId || !loaded(state)) {
        return state;
      }

      return {
        ...state,
        fundamentalPeriodicityTest: defaultFundamentalPeriodicityTestWithLoadingState(LoadingState.NotFound),
      };
    }
  ),
  on(
    DataValidationActions.signalFundamentalPeriodicityTestFetchFailed,
    (state: DataValidationState, { signalId }: { signalId: AStrionSignalId }): DataValidationState => {
      if (signalId !== state.signalId || !loaded(state)) {
        return state;
      }

      return {
        ...state,
        fundamentalPeriodicityTest: defaultFundamentalPeriodicityTestWithLoadingState(LoadingState.Error),
      };
    }
  )
);
