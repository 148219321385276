import { GraphCurve } from '@astrion-webtools/graph';
import { SensorId } from '@features/sensor-signals/shared/interface/sensor-id.interface';

export const name = 'sensor-graphs';

export interface SensorGraphsState {
  currentSensorId: SensorId | null;
  sensorsCurves: Record<SensorId, Record<string, GraphCurve>>;
}

export const SENSOR_GRAPHS_INITIAL_STATE: SensorGraphsState = {
  currentSensorId: null,
  sensorsCurves: {},
};
