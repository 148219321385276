import { Component, computed, input } from '@angular/core';
import { GlGraphComponent, GraphCurve, GraphInput } from '@astrion-webtools/graph';

@Component({
  selector: 'app-stationarity-detection-projection',
  standalone: true,
  imports: [GlGraphComponent],
  templateUrl: './stationarity-detection-projection.component.html',
  styles: ``,
})
export class StationarityDetectionProjectionComponent {
  projection = input.required<GraphCurve>();
  data = computed<GraphInput>(() => ({
    curves: [this.projection()],
  }));
}
