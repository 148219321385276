import { Component, computed, input } from '@angular/core';
import { GlGraphComponent, GraphCurve, GraphInput, Line, LineType } from '@astrion-webtools/graph';

@Component({
  selector: 'app-stationarity-variance',
  standalone: true,
  imports: [GlGraphComponent],
  templateUrl: './stationarity-variance.component.html',
})
export class StationarityVarianceComponent {
  kappa = input.required<number>();
  variance = input.required<GraphCurve>();
  lines = computed<Line[]>(() => [
    {
      type: LineType.Vertical,
      value: this.kappa(),
      color: '#379612',
    },
  ]);
  data = computed<GraphInput>(() => ({
    curves: [this.variance()],
  }));
}
