import { Injectable } from '@angular/core';
import { RealtimeClientService } from '@features/realtime/shared/services/realtime-client.service';
import { signalOverviewFeature } from '@features/signal-overview/shared/store/signal-overview.feature';
import { ComputationStepProgress } from '@features/signal-report/shared/interface/computation-step-progress';
import { Store } from '@ngrx/store';
import { firstValueFrom } from 'rxjs';

import { DataValidationActions } from '../store/data-validation.actions';
import { dataValidationFeature } from '../store/data-validation.feature';
import { DataValidationStepCompletedMessage } from './interfaces';

@Injectable({
  providedIn: 'root',
})
export class DataValidationRealtimeRegistrationService {
  constructor(private store: Store) {}

  public registerMessageHandlers(realtimeClient: RealtimeClientService): void {
    realtimeClient.registerMessageHandlers([
      { messageType: 'TimeSaturationTestComputed', callback: this.timeSaturationComputedHandler },
      { messageType: 'SamplingTestComputed', callback: this.samplingTestComputedHandler },
      { messageType: 'StationarityTestComputed', callback: this.stationarityComputedHandler },
      { messageType: 'FundamentalPeriodicityTestComputed', callback: this.fundamentalPeriodicityComputedHandler },
    ]);
  }

  private timeSaturationComputedHandler = async (msg: unknown): Promise<void> => {
    const computationStepCompletedMessage = msg as DataValidationStepCompletedMessage;

    const receivedSignalId = computationStepCompletedMessage.signalId;
    const reportSignalId = await firstValueFrom(this.store.select(dataValidationFeature.selectSignalId));

    if (reportSignalId !== receivedSignalId) {
      return;
    }

    const timeSaturation = await firstValueFrom(this.store.select(dataValidationFeature.selectSaturationTest));

    if (timeSaturation === undefined || timeSaturation.progress === ComputationStepProgress.Completed) {
      return;
    }

    this.store.dispatch(DataValidationActions.timeSaturationTestFetchRequested({ signalId: receivedSignalId }));
  };

  private samplingTestComputedHandler = async (msg: unknown): Promise<void> => {
    const computationStepCompletedMessage = msg as DataValidationStepCompletedMessage;

    const receivedSignalId = computationStepCompletedMessage.signalId;
    const reportSignalId = await firstValueFrom(this.store.select(dataValidationFeature.selectSignalId));

    if (reportSignalId !== receivedSignalId) {
      return;
    }

    const signalData = await firstValueFrom(this.store.select(signalOverviewFeature.selectSignalData));

    if (signalData === undefined) {
      return;
    }

    const samplingTest = await firstValueFrom(this.store.select(dataValidationFeature.selectSamplingTest));

    if (samplingTest === undefined || samplingTest.progress === ComputationStepProgress.Completed) {
      return;
    }

    this.store.dispatch(
      DataValidationActions.samplingTestFetchRequested({
        signalId: receivedSignalId,
        samplingFrequency: signalData.metadata.samplingFrequency,
      })
    );
  };

  private stationarityComputedHandler = async (msg: unknown): Promise<void> => {
    const computationStepCompletedMessage = msg as DataValidationStepCompletedMessage;

    const receivedSignalId = computationStepCompletedMessage.signalId;
    const reportSignalId = await firstValueFrom(this.store.select(dataValidationFeature.selectSignalId));

    if (reportSignalId !== receivedSignalId) {
      return;
    }

    const stationarity = await firstValueFrom(this.store.select(dataValidationFeature.selectStationarityTest));

    if (stationarity === undefined || stationarity.progress === ComputationStepProgress.Completed) {
      return;
    }

    this.store.dispatch(DataValidationActions.stationarityTestFetchRequested({ signalId: receivedSignalId }));
  };

  private fundamentalPeriodicityComputedHandler = async (msg: unknown): Promise<void> => {
    const computationStepCompletedMessage = msg as DataValidationStepCompletedMessage;

    const receivedSignalId = computationStepCompletedMessage.signalId;
    const reportSignalId = await firstValueFrom(this.store.select(dataValidationFeature.selectSignalId));

    if (reportSignalId !== receivedSignalId) {
      return;
    }

    const signalData = await firstValueFrom(this.store.select(signalOverviewFeature.selectSignalData));

    if (signalData === undefined) {
      return;
    }

    const periodicity = await firstValueFrom(this.store.select(dataValidationFeature.selectFundamentalPeriodicityTest));

    if (periodicity === undefined || periodicity.progress === ComputationStepProgress.Completed) {
      return;
    }

    this.store.dispatch(
      DataValidationActions.signalFundamentalPeriodicityTestFetchRequested({
        signalId: receivedSignalId,
        samplingFrequency: signalData.metadata.samplingFrequency,
      })
    );
  };
}
