import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Guid } from '@app-types/guid.type';
import { DataType, HttpWrapperService, makeBase64Field } from '@astrion-webtools/graph';
import environment from '@environments/environment.json';
import { SensorId } from '@features/sensor-signals/shared/interface/sensor-id.interface';
import { SensorTrajectoriesDto } from '@features/sensor-trajectories/shared/interfaces/trajectory.interface';
import { TrajectoryTrendDto } from '@features/sensor-trajectories/shared/interfaces/trajectory-trend.interface';
import { ASTRION_INDEXEDDB_NAME, ASTRION_INDEXEDDB_TABLES } from '@shared/constants/astrion-indexeddb';
import { Observable } from 'rxjs';

@Injectable()
export class TrajectoriesApiService {
  private base_url: string;

  constructor(
    private httpClient: HttpClient,
    private api: HttpWrapperService
  ) {
    this.base_url = `${environment.BASE_API_URL}/tracking`;
  }

  flagAsDirty(sensorId: SensorId) {
    return this.httpClient.put(`${this.base_url}/trajectories/flag-dirty?sensorId=${sensorId}`, null);
  }

  getSensorTrajectories(sensorId: SensorId) {
    return this.httpClient.get<SensorTrajectoriesDto>(`${this.base_url}/trajectories?sensorId=${sensorId}`);
  }

  getTrajectoryFrequencyTrend(id: Guid): Observable<TrajectoryTrendDto> {
    return this.api.get<TrajectoryTrendDto>(`${this.base_url}/trends/frequency?trajectoryId=${id}`, {
      storage: this.getTrendStorage(`${id}-frequency`),
    });
  }

  getTrajectoryEnergyTrend(id: Guid): Observable<TrajectoryTrendDto> {
    return this.api.get<TrajectoryTrendDto>(`${this.base_url}/trends/energy?trajectoryId=${id}`, {
      storage: this.getTrendStorage(`${id}-energy`),
    });
  }

  private getTrendStorage(id: string) {
    return [
      makeBase64Field({
        id,
        dbName: ASTRION_INDEXEDDB_NAME,
        storeName: ASTRION_INDEXEDDB_TABLES.trajectoryTrend,
        dataType: DataType.Float64,
        storeType: DataType.Float64,
        path: 'interlacedValues',
      }),
    ];
  }
}
