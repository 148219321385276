import { Component, computed, input } from '@angular/core';
import { GlGraphComponent, GraphHeatmap, GraphInput } from '@astrion-webtools/graph';
import { GraphId } from '@astrion-webtools/graph/lib/gl-graph/interface/graph-id.type';

@Component({
  selector: 'app-stationarity-heatmap',
  standalone: true,
  imports: [GlGraphComponent],
  templateUrl: './stationarity-heatmap.component.html',
  styles: ``,
})
export class StationarityHeatmapComponent {
  id = input.required<GraphId>();
  heatmap = input.required<GraphHeatmap>();
  graphTitle = input.required<string>();

  data = computed<GraphInput>(() => ({
    heatmaps: [this.heatmap()],
  }));
}
