import { IndexedDbRowId } from '@astrion-webtools/graph';
import { AStrionSignalId } from '@features/signals/shared/interface/astrion-signal.interface';
import { createActionGroup, emptyProps, props } from '@ngrx/store';

import { DataValidation } from '../interface/data-validation';
import { FundamentalPeriodicityTest } from '../interface/fundamental-periodicity';
import { ShannonTest } from '../interface/sampling-test';
import { Stationarity } from '../interface/stationarity';
import { TimeSaturation } from '../interface/time-saturation';

export const DataValidationActions = createActionGroup({
  source: 'DataValidation',
  events: {
    dataValidationReset: props<{ signalId: AStrionSignalId | null }>(),

    dataValidationFetchRequested: props<{ signalId: AStrionSignalId; samplingFrequency: number }>(),
    dataValidationFetched: props<{ signalId: AStrionSignalId; dataValidation: DataValidation }>(),
    dataValidationNotFound: props<{ signalId: AStrionSignalId }>(),
    dataValidationFetchFailed: props<{ signalId: AStrionSignalId }>(),

    timeSaturationTestFetchRequested: props<{ signalId: AStrionSignalId }>(),
    timeSaturationTestFetched: props<{ signalId: AStrionSignalId; saturationTest: TimeSaturation }>(),
    timeSaturationTestNotFound: props<{ signalId: AStrionSignalId }>(),
    timeSaturationTestFetchFailed: props<{ signalId: AStrionSignalId }>(),

    samplingTestFetchRequested: props<{ signalId: AStrionSignalId; samplingFrequency: number }>(),
    samplingTestFetched: props<{ signalId: AStrionSignalId; samplingTest: ShannonTest }>(),
    samplingTestNotFound: props<{ signalId: AStrionSignalId }>(),
    samplingTestFetchFailed: props<{ signalId: AStrionSignalId }>(),

    samplingTestLinearBytesFetchRequested: props<{
      signalId: AStrionSignalId;
      samplingFrequency: number;
      contentPath: string;
    }>(),
    samplingTestLinearBytesFetched: props<{ signalId: AStrionSignalId; dataId: IndexedDbRowId }>(),
    samplingTestLinearBytesNotFound: props<{ signalId: AStrionSignalId }>(),
    samplingTestLinearBytesFetchFailed: props<{ signalId: AStrionSignalId }>(),

    samplingTestdBBytesFetchRequested: props<{
      signalId: AStrionSignalId;
      samplingFrequency: number;
      contentPath: string;
    }>(),
    samplingTestdBBytesFetched: props<{ signalId: AStrionSignalId; dataId: IndexedDbRowId }>(),
    samplingTestdBBytesNotFound: props<{ signalId: AStrionSignalId }>(),
    samplingTestdBBytesFetchFailed: props<{ signalId: AStrionSignalId }>(),

    samplingTestNoiseBytesFetchRequested: props<{
      signalId: AStrionSignalId;
      samplingFrequency: number;
      contentPath: string;
    }>(),
    samplingTestNoiseBytesFetched: props<{ signalId: AStrionSignalId; dataId: IndexedDbRowId }>(),
    samplingTestNoiseBytesNotFound: props<{ signalId: AStrionSignalId }>(),
    samplingTestNoiseBytesFetchFailed: props<{ signalId: AStrionSignalId }>(),

    samplingTestBytesFetchRejected: emptyProps(),

    stationarityTestFetchRequested: props<{ signalId: AStrionSignalId }>(),
    stationarityTestFetched: props<{ signalId: AStrionSignalId; stationarityTest: Stationarity }>(),
    stationarityTestNotFound: props<{ signalId: AStrionSignalId }>(),
    stationarityTestFetchFailed: props<{ signalId: AStrionSignalId }>(),

    signalFundamentalPeriodicityTestFetchRequested: props<{ signalId: AStrionSignalId; samplingFrequency: number }>(),
    signalFundamentalPeriodicityTestFetched: props<{
      signalId: AStrionSignalId;
      periodicity: FundamentalPeriodicityTest;
    }>(),
    signalFundamentalPeriodicityTestNotFound: props<{ signalId: AStrionSignalId }>(),
    signalFundamentalPeriodicityTestFetchFailed: props<{ signalId: AStrionSignalId }>(),
  },
});
