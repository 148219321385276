import { GraphCurve } from '@astrion-webtools/graph';
import { ValidationFlags, ValidationFlagsDto } from '@features/data-validation/shared/interface/validation-flags';

import { AStrionSignal, AStrionSignalDto } from './astrion-signal.interface';

export interface SensorSignal extends AStrionSignal {
  validationFlags: ValidationFlags;
}

function getNonStationnarityPercentage(sensorSignal: SensorSignal) {
  return sensorSignal.validationFlags.nonStationnarity.percentage;
}

export function getNonStationnarityCurve(signals: SensorSignal[]): GraphCurve {
  // TODO we do not create a buffer (Float64Array), because this function is used by a selector
  // and transfering it to the webworker would mutate the selector output, which seems weird
  // Maybe storing it to indexedDb would be the smartest
  const values = signals
    .filter(signal => !!signal.date)
    .sort((a, b) => a.date!.getTime() - b.date!.getTime())
    .map(signal => [signal.date!.getTime(), getNonStationnarityPercentage(signal) ?? NaN])
    .flat();

  return {
    id: 'Non stationnarity rate',
    color: '#1074ff',
    data: {
      array: {
        keepFloat64: true,
        values,
      },
    },
  };
}

export interface SensorSignalsDto {
  signals: AStrionSignalDto[];
  validationFlags: ValidationFlagsDto[];
}
