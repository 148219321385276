import { SensorSignal } from '@features/signals/shared/interface/sensor-signal.interface';

import { SensorId } from '../interface/sensor-id.interface';

export const name = 'sensor-signals';

export interface SensorSignalsState {
  sensorId: SensorId | null;
  signals: SensorSignal[];
}

export const SENSOR_SIGNALS_INITIAL_STATE: SensorSignalsState = {
  sensorId: null,
  signals: [],
};
