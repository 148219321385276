@if (allowOpenInNew()) {
  <button mat-menu-item (click)="openInNew.emit()">
    <mat-icon>open_in_new</mat-icon>
    <span>Open in new tab</span>
  </button>
}

@if (allowDownload()) {
  <button mat-menu-item (click)="download.emit()">
    <mat-icon>file_download</mat-icon>
    <span>Download</span>
  </button>
}
@if (allowEdit()) {
  <button mat-menu-item (click)="rename.emit()">
    <mat-icon>edit</mat-icon>
    <span>Rename</span>
  </button>
}
@if (allowEdit()) {
  <button mat-menu-item (click)="delete.emit()">
    <mat-icon>delete</mat-icon>
    <span>Delete</span>
  </button>
}
