import { ValidationFlags } from '@features/data-validation/shared/interface/validation-flags';
import { AStrionSignalId } from '@features/signals/shared/interface/astrion-signal.interface';
import { createActionGroup, emptyProps, props } from '@ngrx/store';

import { SensorId } from '../interface/sensor-id.interface';

export const SensorSignalsActions = createActionGroup({
  source: 'SensorSignals',
  events: {
    validationFlagsFetchRequested: props<{ signalId: AStrionSignalId }>(),
    validationFlagsFetchfailed: emptyProps(),
    validationFlagsFetched: props<{ signalId: AStrionSignalId; validationFlags: ValidationFlags }>(),

    flagSensorDataValidationAsDirtyRequested: props<{ sensorId: SensorId }>(),
    flagSensorPeakIdentificationAsDirtyRequested: props<{ sensorId: SensorId }>(),
    flagSensorTrackingAsDirtyRequested: props<{ sensorId: SensorId }>(),

    flagSensorAsDirtyFailed: emptyProps(),

    noAction: emptyProps(),
  },
});
