import { ChangeDetectionStrategy, Component, computed, input, signal } from '@angular/core';
import { Colormap, GraphCurve, GraphHeatmap } from '@astrion-webtools/graph';
import { StationarityData } from '@features/data-validation/shared/interface/stationarity';

import { StatusBannerComponent } from '../status-banner/status-banner.component';
import { ColorScaleComponent } from './color-scale/color-scale.component';
import { StationarityDetectionProjectionComponent } from './stationarity-detection-projection/stationarity-detection-projection.component';
import { StationarityHeatmapComponent } from './stationarity-heatmap/stationarity-heatmap.component';
import { StationarityVarianceComponent } from './stationarity-variance/stationarity-variance.component';

@Component({
  selector: 'app-stationarity',
  standalone: true,
  imports: [
    StatusBannerComponent,
    StationarityHeatmapComponent,
    StationarityVarianceComponent,
    StationarityDetectionProjectionComponent,
    ColorScaleComponent,
  ],
  templateUrl: './stationarity.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StationarityComponent {
  public stationarity = input.required<StationarityData>();

  timeFrequencyColormap = signal<Colormap | string>('LegacyStationaritySpectrogram');
  nonStationarityColormap = signal<Colormap | string>('FaultDetectionProjection');

  timeFrequencyHeatmap = computed<GraphHeatmap>(() => {
    const stationarity = this.stationarity();
    const spectrogram = stationarity.spectrogram;

    return {
      id: 'time-frequency-heatmap',
      colormap: this.timeFrequencyColormap(),
      data: {
        image: {
          base64: spectrogram.base64EncodedImage,
          xMin: 0.0,
          xMax: stationarity.duration,
          yMin: 0, // TODO - set to min frequency once the stationarity min frequency bug is resolved
          yMax: stationarity.maxFrequency,
          zMin: 10 * Math.log10(spectrogram.psdMin),
          zMax: 10 * Math.log10(spectrogram.psdMax),
        },
      },
    };
  });
  timeFrequencyMinValue = computed(() => Math.floor(this.timeFrequencyHeatmap().data.image!.zMin));
  timeFrequencyMaxvalue = computed(() => Math.ceil(this.timeFrequencyHeatmap().data.image!.zMax));

  nonStationarityHeatmap = computed<GraphHeatmap>(() => {
    const stationarity = this.stationarity();
    const stationarityData = stationarity.stationarityData;

    return {
      id: 'time-frequency-non-stationarity',
      colormap: this.nonStationarityColormap(),
      data: {
        image: {
          base64: stationarityData.base64EncodedImage,
          xMin: 0.0,
          xMax: stationarity.duration,
          yMin: 0, // TODO - set to min frequency once the stationarity min frequency bug is resolved
          yMax: stationarity.maxFrequency,
          zMin: stationarityData.minValue,
          zMax: stationarityData.maxValue,
          imageMin: 0,
          imageMax: 4,
        },
      },
    };
  });
  nonStationarityMinValue = computed(() => this.stationarity().stationarityData.minValue);
  nonStationarityMaxValue = computed(() => this.stationarity().stationarityData.maxValue);

  kappa = computed<number>(() => this.stationarity().stationarityData.kappa);
  variance = computed<GraphCurve>(() => {
    console.log(this.kappa());
    const stationarity = this.stationarity();
    const stationarityResultsData = stationarity.stationarityData;

    return {
      id: 'stationarity-variance',
      color: '#BD1814',
      data: {
        array: {
          values: stationarityResultsData.variance,
          yMin: stationarity.minFrequency,
          yMax: stationarity.maxFrequency,
        },
      },
    };
  });

  projection = computed<GraphCurve>(() => {
    const stationarity = this.stationarity();
    return {
      id: 'stationarity-projection',
      displayName: 'Projection',
      color: '#1F618D',
      data: {
        array: {
          values: stationarity.stationarityData.projection,
          xMin: 0,
          xMax: stationarity.duration,
        },
      },
    };
  });

  public statusMessage = computed(() => {
    const stationarity = this.stationarity();

    const nonStationarityPercentage = stationarity.stationarityData.detectionIndex;
    const thresholds = stationarity.thresholds;

    if (nonStationarityPercentage < thresholds.stationarity) {
      return 'Signal is stationary.';
    }

    let message = 'Signal has a ';

    message +=
      nonStationarityPercentage < thresholds.lowNonStationarity
        ? 'low'
        : nonStationarityPercentage < thresholds.mediumNonStationarity
          ? 'medium'
          : 'high';

    message += ` non-stationarity rate of ${nonStationarityPercentage}% (0% corresponds to a stationary signal).`;

    return message;
  });
}
