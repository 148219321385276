@if (data().curves !== undefined) {
  <lib-gl-graph
    class="h-full w-full"
    id="sampling-test"
    [data]="data()"
    [lines]="lines()"
    [yScale]="dbScale"
    xTitle="Frequency (Hz)"
    yTitle="PSD (dB)" />
}
