import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { APP_INITIALIZER, ErrorHandler, importProvidersFrom, isDevMode } from '@angular/core';
import { MAT_DIALOG_DEFAULT_OPTIONS } from '@angular/material/dialog';
import { MatIconRegistry } from '@angular/material/icon';
import { bootstrapApplication } from '@angular/platform-browser';
import { provideAnimations } from '@angular/platform-browser/animations';
import { Router } from '@angular/router';
import {
  GL_GRAPH_WORKER,
  GlGraphWebWorkerThread,
  HTTP_WRAPPER_WORKER,
  HttpWrapperWebWorkerThread,
  setIndexedDbStructure,
} from '@astrion-webtools/graph';
import environment from '@environments/environment.json';
import { AuthInterceptor } from '@features/auth/auth.interceptor';
import { AuthModule } from '@features/auth/auth.module';
import { DataValidationModule } from '@features/data-validation/data-validation.module';
import { FilesExplorerModule } from '@features/files-explorer/files-explorer.module';
import { FoldersModule } from '@features/folders/folders.module';
import { RealtimeModule } from '@features/realtime/realtime.module';
import { SensorGraphsModule } from '@features/sensor-graphs/sensor-graphs.module';
import { SensorSignalsModule } from '@features/sensor-signals/sensor-signals.module';
import { SensorTrajectoriesModule } from '@features/sensor-trajectories/sensor-trajectories.module';
import { SignalReportModule } from '@features/signal-report/signal-report.module';
import { SignalsModule } from '@features/signals/signals.module';
import { ErrorHandlingModule } from '@modules/error-handling/error-handling.module';
import { EffectsModule } from '@ngrx/effects';
import { StoreRouterConnectingModule } from '@ngrx/router-store';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import packageJson from '@root/../package.json';
import * as Sentry from '@sentry/angular';
import { ASTRION_INDEXEDDB } from '@shared/constants/astrion-indexeddb';

import { AppComponent } from './app/app.component';
import { AppRoutingModule } from './app/app-routing.module';
import { ROOT_EFFECTS, ROOT_REDUCERS } from './app/shared/store';
import { RootInjector } from './root.injector';

function setMatIconsRegistry(iconRegistry: MatIconRegistry) {
  return () => iconRegistry.setDefaultFontSetClass('material-symbols-outlined');
}

Sentry.init({
  dsn: environment.SENTRY_DSN,
  environment: environment.ENV.toLowerCase(),
  integrations: [
    Sentry.captureConsoleIntegration(),
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
    Sentry.httpClientIntegration(),
    Sentry.sessionTimingIntegration(),
    Sentry.feedbackIntegration({
      colorScheme: 'light',
      triggerLabel: '',
      showBranding: false,
    }),
  ],
  beforeSend(event) {
    // Check if it is an exception, and if so, show the report dialog (not in production)
    if (event.exception && event.event_id && environment.ENV.toLowerCase() != 'production') {
      Sentry.showReportDialog({ eventId: event.event_id, user: event.user });
    }
    return event;
  },
  release: 'astrion-web@' + packageJson.version,
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: [
    'localhost',
    /^https:\/\/staging\.astrion\.online/,
    /^https:\/\/api\.staging\.astrion\.online/,
  ],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

bootstrapApplication(AppComponent, {
  providers: [
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: true,
      }),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useValue: async () => await setIndexedDbStructure(ASTRION_INDEXEDDB),
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      deps: [MatIconRegistry],
      useFactory: setMatIconsRegistry,
      multi: true,
    },
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    provideAnimations(),
    provideHttpClient(withInterceptorsFromDi()),
    importProvidersFrom(
      StoreModule.forRoot(ROOT_REDUCERS),
      StoreRouterConnectingModule.forRoot(),
      EffectsModule.forRoot(ROOT_EFFECTS),
      StoreDevtoolsModule.instrument({ maxAge: 25, logOnly: !isDevMode() }),
      RealtimeModule.forRoot(),
      AuthModule,
      AppRoutingModule,
      FoldersModule,
      SignalsModule,
      SignalReportModule,
      FilesExplorerModule,
      SensorGraphsModule,
      SensorTrajectoriesModule,
      ErrorHandlingModule,
      DataValidationModule,
      SensorSignalsModule
    ),
    {
      provide: MAT_DIALOG_DEFAULT_OPTIONS,
      useValue: {
        maxWidth: '90vw',
      },
    },
    {
      provide: GL_GRAPH_WORKER,
      useValue: new GlGraphWebWorkerThread(new Worker(new URL('./graph-worker.worker.ts', import.meta.url))),
    },
    {
      provide: HTTP_WRAPPER_WORKER,
      useValue: new HttpWrapperWebWorkerThread(new Worker(new URL('./http-worker.worker.ts', import.meta.url))),
    },
  ],
})
  .then(ngModuleRef => {
    RootInjector.setInjector(ngModuleRef.injector);
  })
  .catch(err => console.error(err));
