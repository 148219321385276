import { createReducer, on } from '@ngrx/store';

import { SensorGraphsActions } from './sensor-graphs.actions';
import { SENSOR_GRAPHS_INITIAL_STATE, SensorGraphsState } from './sensor-graphs.state';

function removeFromRecord<T>(record: Record<string, T>, property: string) {
  return Object.fromEntries(Object.entries(record).filter(([key]) => key !== property));
}

export const reducer = createReducer(
  SENSOR_GRAPHS_INITIAL_STATE,
  on(
    SensorGraphsActions.setCurrentSensor,
    (state, { sensorId }): SensorGraphsState => ({
      ...state,
      currentSensorId: sensorId,
    })
  ),
  on(SensorGraphsActions.clearCurves, (state): SensorGraphsState => {
    return state.currentSensorId
      ? {
          ...state,
          sensorsCurves: { ...state.sensorsCurves, [state.currentSensorId]: {} },
        }
      : state;
  }),
  on(
    SensorGraphsActions.addCurve,
    (state, { curveId, curve }): SensorGraphsState =>
      state.currentSensorId
        ? {
            ...state,
            sensorsCurves: {
              ...state.sensorsCurves,
              [state.currentSensorId]: { ...state.sensorsCurves[state.currentSensorId], [curveId]: curve },
            },
          }
        : state
  ),
  on(
    SensorGraphsActions.removeCurve,
    (state, { curveId }): SensorGraphsState =>
      state.currentSensorId
        ? {
            ...state,
            sensorsCurves: {
              ...state.sensorsCurves,
              [state.currentSensorId]: removeFromRecord(state.sensorsCurves[state.currentSensorId], curveId),
            },
          }
        : state
  )
);
