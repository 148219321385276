import { Folder, FolderId } from '@features/folders/shared/interface/folder.interface';
import { foldersFeature } from '@features/folders/shared/store/folders.feature';
import { FoldersUri, getFolderId, getPathFromRoot } from '@features/folders/shared/utils/folders-uri';
import { createSelector } from '@ngrx/store';
import { selectUrl } from '@store/router.selectors';

import { ExplorerItem, ExplorerItemType } from '../interface/explorer-item.interface';
import { FILES_EXPLORER_ROOT } from '../utils/files-explorer-root';

const folder2item = (folder: Folder): ExplorerItem => ({
  type: ExplorerItemType.Folder,
  object: folder,
});

/**
 * append files-explorer root path to base folder paths
 */
export const selectFoldersExplorerUris = createSelector(
  foldersFeature.selectFoldersUri,
  foldersUri =>
    ({
      folderIdToUri: Object.fromEntries(
        Object.entries(foldersUri.folderIdToUri).map(([folderId, path]) => [folderId, `/${FILES_EXPLORER_ROOT}${path}`])
      ),
      uriToFolderId: Object.fromEntries(
        Object.entries(foldersUri.uriToFolderId).map(([path, folderId]) => [`/${FILES_EXPLORER_ROOT}${path}`, folderId])
      ),
    }) as FoldersUri
);

export const selectCurrentFolderId = createSelector(
  selectUrl,
  selectFoldersExplorerUris,
  (url, foldersUri): FolderId | null => getFolderId(foldersUri, url)
);

export const selectCurrentFolder = createSelector(
  selectCurrentFolderId,
  foldersFeature.selectFlattenedFoldersTree,
  (folderId, flatTree): Folder | null => (folderId ? flatTree.folders[folderId] ?? null : null)
);

export const selectUriFoldersPath = createSelector(
  selectCurrentFolder,
  foldersFeature.selectFlattenedFoldersTree,
  (folder: Folder | null, foldersTree): Folder[] | null => {
    return folder ? getPathFromRoot(folder, foldersTree) : null;
  }
);

export const selectFolderUri = (folderId: FolderId) =>
  createSelector(foldersFeature.selectFoldersUri, foldersUri => foldersUri.folderIdToUri[folderId]);

export const selectCurrentSubfolders = createSelector(
  foldersFeature.selectFlattenedFoldersTree,
  selectCurrentFolderId,
  (flattenedFoldersTree, currentFolderId): Folder[] =>
    currentFolderId
      ? flattenedFoldersTree.children[currentFolderId].map(folderId => flattenedFoldersTree.folders[folderId])
      : []
);

export const selectExplorerContent = createSelector(selectCurrentSubfolders, (folders): ExplorerItem[][] => [
  folders.map(folder2item),
  [],
]);
