import { Injectable } from '@angular/core';
import { catchApiError } from '@modules/error-handling/app-error.operators';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { DataProxyApiService } from '@services/data-proxy-api.service';
import { map, mergeMap, switchMap } from 'rxjs';

import { PeakIdentificationApiService } from '../services/peak-identification-api.service';
import { mapCyclesFromDto } from '../utils/cycles-mapping';
import { mapFusionFromDto } from '../utils/fusion-mapping';
import { mapPeakIdentificationFromDto } from '../utils/peak-identification-mappings';
import { cycleBytesStorageDescriptor, fusionBytesStorageDescriptor } from '../utils/storage-descriptors';
import { PeakIdentificationActions } from './peak-identification.actions';

@Injectable()
export class PeakIdentificationEffects {
  constructor(
    private actions$: Actions,
    private api: PeakIdentificationApiService,
    private dataProxyApi: DataProxyApiService
  ) {}

  tryFetchSignalPeakIdentificationEffect$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(PeakIdentificationActions.peakIdentificationFetchRequested),
      switchMap(({ signalId }) =>
        this.api.getPeakIdentification(signalId).pipe(
          map(peakIdentificationDto =>
            PeakIdentificationActions.peakIdentificationFetched({
              signalId,
              peakIdentification: mapPeakIdentificationFromDto(peakIdentificationDto),
            })
          ),
          catchApiError(false, apiError => {
            return apiError.httpError.status == 404
              ? PeakIdentificationActions.peakIdentificationNotFound({ signalId })
              : PeakIdentificationActions.peakIdentificationFetchFailed({ signalId });
          })
        )
      )
    );
  });

  emitCyclesFetchedEffect$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(PeakIdentificationActions.peakIdentificationFetched),
      map(({ signalId, peakIdentification }) =>
        PeakIdentificationActions.cyclesFetched({
          signalId,
          cycles: peakIdentification.cycles,
        })
      )
    );
  });

  tryFetchCyclesEffect$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(PeakIdentificationActions.cyclesFetchRequested),
      switchMap(({ signalId }) =>
        this.api.getCyclesData(signalId).pipe(
          map(cyclesDto =>
            PeakIdentificationActions.cyclesFetched({
              signalId,
              cycles: mapCyclesFromDto(cyclesDto),
            })
          ),
          catchApiError(false, apiError => {
            return apiError.httpError.status == 404
              ? PeakIdentificationActions.cyclesNotFound({ signalId })
              : PeakIdentificationActions.cyclesFetchFailed({ signalId });
          })
        )
      )
    );
  });

  emitInitialCycleBytesFetchRequestEffect$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(PeakIdentificationActions.cyclesFetched),
      map(({ signalId, cycles }) =>
        cycles.data === undefined
          ? PeakIdentificationActions.cycleBytesFetchRejected()
          : PeakIdentificationActions.cycleBytesFetchEmissionRequested({ signalId, cycleIndex: 0, cycles })
      )
    );
  });

  emitNextCycleBytesFetchRequestEffect$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(PeakIdentificationActions.cycleBytesFetchEmissionRequested),
      map(({ signalId, cycleIndex, cycles }) =>
        cycles.data === undefined
          ? PeakIdentificationActions.cycleBytesFetchRejected()
          : cycleIndex === cycles.data!.length - 1
            ? PeakIdentificationActions.noAction()
            : PeakIdentificationActions.cycleBytesFetchEmissionRequested({
                signalId,
                cycleIndex: cycleIndex + 1,
                cycles,
              })
      )
    );
  });

  emitCycleSpectrumBytesFetchRequest$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(PeakIdentificationActions.cycleBytesFetchEmissionRequested),
      map(({ signalId, cycleIndex, cycles }) =>
        cycles.data === undefined
          ? PeakIdentificationActions.cycleBytesFetchRejected()
          : cycleIndex >= cycles.data.length
            ? PeakIdentificationActions.invalidCycleIndexBytesFetchEmissionRequested({ signalId, cycleIndex })
            : PeakIdentificationActions.cycleSpectrumBytesFetchRequested({
                signalId,
                cycleIndex,
                contentPath: cycles.data![cycleIndex].spectrum.amplitudes.contentPath,
                freqMin: cycles.data![cycleIndex].spectrum.freqMin,
                freqMax: cycles.data![cycleIndex].spectrum.freqMax,
              })
      )
    );
  });

  emitCycleNoiseBytesFetchRequest$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(PeakIdentificationActions.cycleBytesFetchEmissionRequested),
      map(({ signalId, cycleIndex, cycles }) =>
        cycles.data === undefined
          ? PeakIdentificationActions.cycleBytesFetchRejected()
          : cycleIndex >= cycles.data.length
            ? PeakIdentificationActions.invalidCycleIndexBytesFetchEmissionRequested({ signalId, cycleIndex })
            : PeakIdentificationActions.cycleNoiseBytesFetchRequested({
                signalId,
                cycleIndex,
                contentPath: cycles.data![cycleIndex].noise.amplitudes.contentPath,
                freqMin: cycles.data![cycleIndex].noise.freqMin,
                freqMax: cycles.data![cycleIndex].noise.freqMax,
              })
      )
    );
  });

  tryFetchCycleSpectrumBytesEffect$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(PeakIdentificationActions.cycleSpectrumBytesFetchRequested),
      mergeMap(({ signalId, cycleIndex, contentPath, freqMin, freqMax }) =>
        this.dataProxyApi
          .getDataBytes(
            contentPath,
            cycleBytesStorageDescriptor(`${signalId}_cycle_${cycleIndex}_spectrum`, freqMin, freqMax)
          )
          .pipe(
            map(dbRowId =>
              PeakIdentificationActions.cycleSpectrumBytesFetched({ signalId, cycleIndex, dataId: dbRowId })
            ),
            catchApiError(false, apiError => {
              return apiError.httpError.status == 404
                ? PeakIdentificationActions.cycleSpectrumBytesNotFound({ signalId, cycleIndex })
                : PeakIdentificationActions.cycleSpectrumBytesFetchFailed({ signalId, cycleIndex });
            })
          )
      )
    );
  });

  tryFetchCycleNoiseBytesEffect$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(PeakIdentificationActions.cycleNoiseBytesFetchRequested),
      mergeMap(({ signalId, cycleIndex, contentPath, freqMin, freqMax }) =>
        this.dataProxyApi
          .getDataBytes(
            contentPath,
            cycleBytesStorageDescriptor(`${signalId}_cycle_${cycleIndex}_noise`, freqMin, freqMax)
          )
          .pipe(
            map(dbRowId => PeakIdentificationActions.cycleNoiseBytesFetched({ signalId, cycleIndex, dataId: dbRowId })),
            catchApiError(false, apiError => {
              return apiError.httpError.status == 404
                ? PeakIdentificationActions.cycleNoiseBytesNotFound({ signalId, cycleIndex })
                : PeakIdentificationActions.cycleNoiseBytesFetchFailed({ signalId, cycleIndex });
            })
          )
      )
    );
  });

  emitFusionFetchedEffect$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(PeakIdentificationActions.peakIdentificationFetched),
      map(({ signalId, peakIdentification }) =>
        PeakIdentificationActions.fusionFetched({
          signalId,
          fusion: peakIdentification.fusion,
        })
      )
    );
  });

  tryFetchFusionEffect$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(PeakIdentificationActions.fusionFetchRequested),
      switchMap(({ signalId }) =>
        this.api.getFusionData(signalId).pipe(
          map(fusionDto =>
            PeakIdentificationActions.fusionFetched({
              signalId,
              fusion: mapFusionFromDto(fusionDto),
            })
          ),
          catchApiError(false, apiError => {
            return apiError.httpError.status == 404
              ? PeakIdentificationActions.fusionNotFound({ signalId })
              : PeakIdentificationActions.fusionFetchFailed({ signalId });
          })
        )
      )
    );
  });

  emitFusionNoiseMinBytesFetchRequestEffect$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(PeakIdentificationActions.fusionFetched),
      map(({ signalId, fusion }) =>
        fusion.data === undefined
          ? PeakIdentificationActions.fusionBytesFetchRejected()
          : PeakIdentificationActions.fusionNoiseMinBytesFetchRequested({
              signalId,
              contentPath: fusion.data!.noiseArea.minAmplitudes.contentPath,
              freqMin: fusion.data!.noiseArea.freqMin,
              freqMax: fusion.data!.noiseArea.freqMax,
            })
      )
    );
  });

  emitFusionNoiseMaxBytesFetchRequestEffect$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(PeakIdentificationActions.fusionFetched),
      map(({ signalId, fusion }) =>
        fusion.data === undefined
          ? PeakIdentificationActions.fusionBytesFetchRejected()
          : PeakIdentificationActions.fusionNoiseMaxBytesFetchRequested({
              signalId,
              contentPath: fusion.data!.noiseArea.maxAmplitudes.contentPath,
              freqMin: fusion.data!.noiseArea.freqMin,
              freqMax: fusion.data!.noiseArea.freqMax,
            })
      )
    );
  });

  tryFetchFusionNoiseMinBytesEffect$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(PeakIdentificationActions.fusionNoiseMinBytesFetchRequested),
      switchMap(({ signalId, contentPath, freqMin, freqMax }) =>
        this.dataProxyApi
          .getDataBytes(contentPath, fusionBytesStorageDescriptor(`${signalId}_noise_min`, freqMin, freqMax))
          .pipe(
            map(dbRowId => PeakIdentificationActions.fusionNoiseMinBytesFetched({ signalId, dataId: dbRowId })),
            catchApiError(false, apiError => {
              return apiError.httpError.status == 404
                ? PeakIdentificationActions.fusionNoiseMinBytesNotFound({ signalId })
                : PeakIdentificationActions.fusionNoiseMinBytesFetchFailed({ signalId });
            })
          )
      )
    );
  });

  tryFetchFusionNoiseMaxBytesEffect$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(PeakIdentificationActions.fusionNoiseMaxBytesFetchRequested),
      switchMap(({ signalId, contentPath, freqMin, freqMax }) =>
        this.dataProxyApi
          .getDataBytes(contentPath, fusionBytesStorageDescriptor(`${signalId}_noise_max`, freqMin, freqMax))
          .pipe(
            map(dbRowId => PeakIdentificationActions.fusionNoiseMaxBytesFetched({ signalId, dataId: dbRowId })),
            catchApiError(false, apiError => {
              return apiError.httpError.status == 404
                ? PeakIdentificationActions.fusionNoiseMaxBytesNotFound({ signalId })
                : PeakIdentificationActions.fusionNoiseMaxBytesFetchFailed({ signalId });
            })
          )
      )
    );
  });
}
