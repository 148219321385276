import { FolderId } from '@features/folders/shared/interface/folder.interface';
import { FoldersActions } from '@features/folders/shared/store/folders.actions';
import {
  AStrionSignalUploadResult,
  AStrionSignalUploadStatus,
} from '@features/signals/shared/interface/astrion-signal-upload-result.interface';
import { SensorSignal } from '@features/signals/shared/interface/sensor-signal.interface';
import { SignalsActions } from '@features/signals/shared/store/signals.actions';
import { createReducer, on } from '@ngrx/store';

import { FilesExplorerActions } from './files-explorer.actions';
import { FILES_EXPLORER_INITIAL_STATE, FilesExplorersState } from './files-explorer.state';

export const reducer = createReducer(
  FILES_EXPLORER_INITIAL_STATE,
  on(
    FoldersActions.foldersFetchRequested,
    SignalsActions.folderSignalsFetchRequested,
    (state: FilesExplorersState): FilesExplorersState => ({
      ...state,
      isLoading: true,
    })
  ),
  on(
    SignalsActions.folderSignalsFetched,
    (
      state: FilesExplorersState,
      { folderId, signals }: { folderId: FolderId; signals: SensorSignal[] }
    ): FilesExplorersState => ({
      ...state,
      isLoading: false,
      hasSignals: signals.length > 0,
      currentFolderId: folderId,
    })
  ),
  on(
    SignalsActions.signalsUploaded,
    (state: FilesExplorersState, { uploads }: { uploads: AStrionSignalUploadResult[] }): FilesExplorersState => ({
      ...state,
      hasSignals:
        uploads.filter(
          u => u.status === AStrionSignalUploadStatus.Success && u.signal?.folderId === state.currentFolderId
        ).length > 0,
    })
  ),
  on(
    FilesExplorerActions.fetchFolderSkipAlreadyCached,
    (state: FilesExplorersState): FilesExplorersState => ({
      ...state,
      isLoading: false,
    })
  ),
  on(
    FilesExplorerActions.fetchFolderSkipNoContent,
    (state: FilesExplorersState, { folder }): FilesExplorersState => ({
      ...state,
      isLoading: false,
      hasSignals: false,
      currentFolderId: folder.id,
    })
  ),
  on(
    FoldersActions.foldersFetchFailed,
    SignalsActions.folderSignalsFetchFailed,
    (state: FilesExplorersState): FilesExplorersState => ({
      ...state,
      isLoading: false,
    })
  ),
  on(
    FilesExplorerActions.itemEditionRequested,
    (state: FilesExplorersState, { id }): FilesExplorersState => ({
      ...state,
      currentlyEditedItemId: id,
    })
  ),
  on(
    FilesExplorerActions.editedItemCleared,
    (state: FilesExplorersState): FilesExplorersState => ({
      ...state,
      currentlyEditedItemId: null,
    })
  )
);
