import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BinaryFieldStorage, HttpWrapperService, IndexedDbRowId } from '@astrion-webtools/graph';
import environment from '@environments/environment.json';
import { lastValueFrom, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class DataProxyApiService {
  constructor(
    private httpClient: HttpClient,
    private backgroundApi: HttpWrapperService
  ) {}

  private BASE_DATA_PROXY_URL = `${environment.BASE_API_URL}/proxy`;

  getDataBytes(contentPath: string, storage: BinaryFieldStorage): Observable<IndexedDbRowId> {
    return this.backgroundApi.get<IndexedDbRowId>(`${this.BASE_DATA_PROXY_URL}/computed/${contentPath}`, {
      storage,
    });
  }

  async fetchImageAsBase64(contentPath: string) {
    return lastValueFrom(
      this.httpClient.request('get', `${this.BASE_DATA_PROXY_URL}/image/${contentPath}`, {
        responseType: 'text',
      })
    );
  }
}
