import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, computed, input, Signal } from '@angular/core';
import { AStrionSignal } from '@features/signals/shared/interface/astrion-signal.interface';
import { MaterialModule } from '@modules/material.module';
import { ItemDescription } from '@shared/interfaces/item-description';
import { parseProcessingState } from '@shared/interfaces/processing-status';

@Component({
  selector: 'app-signal-icon',
  standalone: true,
  imports: [CommonModule, MaterialModule],
  templateUrl: './signal-icon.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SignalIconComponent {
  signal = input.required<AStrionSignal>();

  itemDescription: Signal<ItemDescription> = computed(() => {
    const signal = this.signal();
    return parseProcessingState(signal.status);
  });
}
