import { UNKNOWN_FLAGS } from '@features/data-validation/shared/interface/validation-flags';
import { FolderId } from '@features/folders/shared/interface/folder.interface';
import {
  AStrionSignalUploadResult,
  AStrionSignalUploadStatus,
} from '@features/signals/shared/interface/astrion-signal-upload-result.interface';
import { SensorSignal } from '@features/signals/shared/interface/sensor-signal.interface';
import { SignalsActions } from '@features/signals/shared/store/signals.actions';
import { createReducer, on } from '@ngrx/store';
import { ProcessingStatus } from '@shared/interfaces/processing-status';

import { SensorSignalsActions } from './sensor-signals.actions';
import { SENSOR_SIGNALS_INITIAL_STATE, SensorSignalsState } from './sensor-signals.state';

function isDefined<T>(value: T | null | undefined): value is T {
  return value !== null && value !== undefined;
}

export const reducer = createReducer(
  SENSOR_SIGNALS_INITIAL_STATE,
  on(
    SignalsActions.folderSignalsFetched,
    (
      state: SensorSignalsState,
      { folderId, signals }: { folderId: FolderId; signals: SensorSignal[] }
    ): SensorSignalsState => ({
      ...state,
      sensorId: folderId,
      signals,
    })
  ),
  on(
    SignalsActions.signalsStatusFetched,
    (state: SensorSignalsState, { statuses }): SensorSignalsState => ({
      ...state,
      signals: state.signals.map(signal => ({
        ...signal,
        status: statuses[signal.id] ?? signal.status,
      })),
    })
  ),
  on(
    SignalsActions.signalDeleted,
    (state: SensorSignalsState, { signalId }): SensorSignalsState => ({
      ...state,
      signals: state.signals.filter(s => s.id != signalId),
    })
  ),
  on(
    SignalsActions.signalUpdated,
    (state: SensorSignalsState, { signal }): SensorSignalsState => ({
      ...state,
      signals: state.signals.map(s => (s.id === signal.id ? { ...signal, validationFlags: s.validationFlags } : s)),
    })
  ),
  on(
    SignalsActions.signalsUploaded,
    (state: SensorSignalsState, { uploads }: { uploads: AStrionSignalUploadResult[] }): SensorSignalsState => ({
      ...state,
      signals: [
        ...state.signals,
        ...uploads
          .filter(upload => upload.status === AStrionSignalUploadStatus.Success)
          .map(upload => upload.signal)
          .filter(isDefined)
          .filter(signal => signal.folderId === state.sensorId)
          .filter(signal => !state.signals.some(cachedSignal => cachedSignal.id === signal.id))
          .map(signal => ({ ...signal, status: ProcessingStatus.InProgress, validationFlags: UNKNOWN_FLAGS })),
      ],
    })
  ),

  on(
    SignalsActions.signalsStatusFetched,
    (state: SensorSignalsState, { statuses }): SensorSignalsState => ({
      ...state,
      signals: state.signals.map(signal => ({
        ...signal,
        status: statuses[signal.id] ?? signal.status,
      })),
    })
  ),
  on(
    SensorSignalsActions.validationFlagsFetched,
    (state: SensorSignalsState, action): SensorSignalsState => ({
      ...state,
      signals: state.signals.map(signal =>
        action.signalId === signal.id ? { ...signal, validationFlags: action.validationFlags } : signal
      ),
    })
  )
);
