<div class="mb-6 flex w-full flex-col gap-8">
  <div class="flex flex-initial flex-col" [appReportAnchor]="REPORT_NAMES.dataValidation.anchors.timeSaturation">
    <h3 appReportAnchorTitle>Time saturation</h3>
    @if (ready(timeSaturation()) && samplesId()) {
      <app-time-saturation [timeSaturation]="timeSaturation().data!" [samplesId]="samplesId()!" />
    } @else {
      <app-result-unavailable [loadableData]="timeSaturation()" class="h-32 w-full" />
    }
  </div>
  <div class="flex flex-initial flex-col" [appReportAnchor]="REPORT_NAMES.dataValidation.anchors.shannonTest">
    <h3 appReportAnchorTitle>Shannon test</h3>
    @if (ready(shannonTest())) {
      <app-shannon-sampling-test [shannonTest]="shannonTest().data!" />
    } @else {
      <app-result-unavailable [loadableData]="shannonTest()" class="h-32 w-full" />
    }
  </div>
  <div class="flex flex-initial flex-col" [appReportAnchor]="REPORT_NAMES.dataValidation.anchors.stationarity">
    <h3 appReportAnchorTitle>Stationarity</h3>
    @if (ready(stationarity())) {
      <app-stationarity [stationarity]="stationarity().data!" />
    } @else {
      <app-result-unavailable [loadableData]="stationarity()" class="h-32 w-full" />
    }
  </div>
</div>
