import { Injectable } from '@angular/core';
import { catchApiError } from '@modules/error-handling/app-error.operators';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { DataProxyApiService } from '@services/data-proxy-api.service';
import { map, switchMap } from 'rxjs';

import { DataValidationApiService } from '../services/data-validation-api.service';
import { mapDataValidationFromDto } from '../utils/data-validation-mapping';
import { mapFundamentalPeriodicityFromDto } from '../utils/fundamental-periodicity-mapping';
import { mapSamplingTestFromDto } from '../utils/sampling-test-mapping';
import { mapStationarityFromDto } from '../utils/stationarity-mapping';
import { samplingTestCriterionBytesStorageDescriptor } from '../utils/storage-descriptors';
import { mapTimeSaturationFromDto } from '../utils/time-saturation-mapping';
import { DataValidationActions } from './data-validation.actions';

@Injectable()
export class DataValidationEffects {
  constructor(
    private actions$: Actions,
    private api: DataValidationApiService,
    private dataProxyApi: DataProxyApiService,
    private store: Store
  ) {}

  tryFetchSignalDataValidationEffect$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(DataValidationActions.dataValidationFetchRequested),
      switchMap(({ signalId, samplingFrequency }) =>
        this.api.getDataValidation(signalId, samplingFrequency).pipe(
          map(dataValidationDto =>
            DataValidationActions.dataValidationFetched({
              signalId,
              dataValidation: mapDataValidationFromDto(dataValidationDto, samplingFrequency),
            })
          ),
          catchApiError(false, apiError => {
            return apiError.httpError.status == 404
              ? DataValidationActions.dataValidationNotFound({ signalId })
              : DataValidationActions.dataValidationFetchFailed({ signalId });
          })
        )
      )
    );
  });

  emitTimeSaturationTestFetchedEffect$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(DataValidationActions.dataValidationFetched),
      map(({ signalId, dataValidation }) =>
        DataValidationActions.timeSaturationTestFetched({
          signalId,
          saturationTest: dataValidation.saturationTest,
        })
      )
    );
  });

  emitSamplingTestFetchedEffect$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(DataValidationActions.dataValidationFetched),
      map(({ signalId, dataValidation }) =>
        DataValidationActions.samplingTestFetched({
          signalId,
          samplingTest: dataValidation.samplingTest,
        })
      )
    );
  });

  emitStationarityTestFetchedEffect$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(DataValidationActions.dataValidationFetched),
      map(({ signalId, dataValidation }) =>
        DataValidationActions.stationarityTestFetched({
          signalId,
          stationarityTest: dataValidation.stationarityTest,
        })
      )
    );
  });

  emitFundamentalPeriodicityTestFetchedEffect$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(DataValidationActions.dataValidationFetched),
      map(({ signalId, dataValidation }) =>
        DataValidationActions.signalFundamentalPeriodicityTestFetched({
          signalId,
          periodicity: dataValidation.fundamentalPeriodicityTest,
        })
      )
    );
  });

  tryFetchSignalTimeSaturationEffect$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(DataValidationActions.timeSaturationTestFetchRequested),
      switchMap(({ signalId }) =>
        this.api.getTimeSaturationTestData(signalId).pipe(
          map(timeSaturationDto =>
            DataValidationActions.timeSaturationTestFetched({
              signalId,
              saturationTest: mapTimeSaturationFromDto(timeSaturationDto),
            })
          ),
          catchApiError(false, apiError => {
            return apiError.httpError.status == 404
              ? DataValidationActions.timeSaturationTestNotFound({ signalId })
              : DataValidationActions.timeSaturationTestFetchFailed({ signalId });
          })
        )
      )
    );
  });

  tryFetchSignalSamplingTestEffect$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(DataValidationActions.samplingTestFetchRequested),
      switchMap(({ signalId, samplingFrequency }) =>
        this.api.getSamplingTestData(signalId).pipe(
          map(samplingTestDto =>
            DataValidationActions.samplingTestFetched({
              signalId,
              samplingTest: mapSamplingTestFromDto(samplingTestDto, samplingFrequency),
            })
          ),
          catchApiError(false, apiError => {
            return apiError.httpError.status == 404
              ? DataValidationActions.samplingTestNotFound({ signalId })
              : DataValidationActions.samplingTestFetchFailed({ signalId });
          })
        )
      )
    );
  });

  emitSamplingTestLinearBytesFetchRequest$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(DataValidationActions.samplingTestFetched),
      map(({ signalId, samplingTest }) =>
        samplingTest.data === undefined
          ? DataValidationActions.samplingTestBytesFetchRejected()
          : DataValidationActions.samplingTestLinearBytesFetchRequested({
              signalId,
              samplingFrequency: samplingTest.data!.samplingFrequency,
              contentPath: samplingTest.data!.linearTestSummary.spectrumAmplitudes.contentPath,
            })
      )
    );
  });

  emitSamplingTestdBBytesFetchRequest$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(DataValidationActions.samplingTestFetched),
      map(({ signalId, samplingTest }) =>
        samplingTest.data === undefined
          ? DataValidationActions.samplingTestBytesFetchRejected()
          : DataValidationActions.samplingTestdBBytesFetchRequested({
              signalId,
              samplingFrequency: samplingTest.data!.samplingFrequency,
              contentPath: samplingTest.data!.dbTestSummary.spectrumAmplitudes.contentPath,
            })
      )
    );
  });

  emitSamplingTestNoiseBytesFetchRequest$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(DataValidationActions.samplingTestFetched),
      map(({ signalId, samplingTest }) =>
        samplingTest.data === undefined
          ? DataValidationActions.samplingTestBytesFetchRejected()
          : DataValidationActions.samplingTestNoiseBytesFetchRequested({
              signalId,
              samplingFrequency: samplingTest.data!.samplingFrequency,
              contentPath: samplingTest.data!.noiseTestSummary.spectrumAmplitudes.contentPath,
            })
      )
    );
  });

  tryFetchSamplingTestLinearBytesEffect$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(DataValidationActions.samplingTestLinearBytesFetchRequested),
      switchMap(({ signalId, samplingFrequency, contentPath }) =>
        this.dataProxyApi
          .getDataBytes(
            contentPath,
            samplingTestCriterionBytesStorageDescriptor(`${signalId}_linear`, samplingFrequency)
          )
          .pipe(
            map(dbRowId => DataValidationActions.samplingTestLinearBytesFetched({ signalId, dataId: dbRowId })),
            catchApiError(false, apiError => {
              return apiError.httpError.status == 404
                ? DataValidationActions.samplingTestLinearBytesNotFound({ signalId })
                : DataValidationActions.samplingTestLinearBytesFetchFailed({ signalId });
            })
          )
      )
    );
  });

  tryFetchSamplingTestdBBytesEffect$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(DataValidationActions.samplingTestdBBytesFetchRequested),
      switchMap(({ signalId, samplingFrequency, contentPath }) =>
        this.dataProxyApi
          .getDataBytes(contentPath, samplingTestCriterionBytesStorageDescriptor(`${signalId}_dB`, samplingFrequency))
          .pipe(
            map(dbRowId => DataValidationActions.samplingTestdBBytesFetched({ signalId, dataId: dbRowId })),
            catchApiError(false, apiError => {
              return apiError.httpError.status == 404
                ? DataValidationActions.samplingTestdBBytesNotFound({ signalId })
                : DataValidationActions.samplingTestdBBytesFetchFailed({ signalId });
            })
          )
      )
    );
  });

  tryFetchSamplingTestNoiseBytesEffect$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(DataValidationActions.samplingTestNoiseBytesFetchRequested),
      switchMap(({ signalId, samplingFrequency, contentPath }) =>
        this.dataProxyApi
          .getDataBytes(
            contentPath,
            samplingTestCriterionBytesStorageDescriptor(`${signalId}_noise`, samplingFrequency)
          )
          .pipe(
            map(dbRowId => DataValidationActions.samplingTestNoiseBytesFetched({ signalId, dataId: dbRowId })),
            catchApiError(false, apiError => {
              return apiError.httpError.status == 404
                ? DataValidationActions.samplingTestNoiseBytesNotFound({ signalId })
                : DataValidationActions.samplingTestNoiseBytesFetchFailed({ signalId });
            })
          )
      )
    );
  });

  tryFetchStationarityEffect$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(DataValidationActions.stationarityTestFetchRequested),
      switchMap(({ signalId }) =>
        this.api.getStationarityTestData(signalId).pipe(
          map(stationarityDto =>
            DataValidationActions.stationarityTestFetched({
              signalId,
              stationarityTest: mapStationarityFromDto(stationarityDto),
            })
          ),
          catchApiError(false, apiError => {
            return apiError.httpError.status == 404
              ? DataValidationActions.stationarityTestNotFound({ signalId })
              : DataValidationActions.stationarityTestFetchFailed({ signalId });
          })
        )
      )
    );
  });

  tryFetchFundamentalPeriodicityEffect$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(DataValidationActions.signalFundamentalPeriodicityTestFetchRequested),
      switchMap(({ signalId, samplingFrequency }) =>
        this.api.getFundamentalPeriodicityTestData(signalId, samplingFrequency).pipe(
          map(fundamentalPeriodicityDto =>
            DataValidationActions.signalFundamentalPeriodicityTestFetched({
              signalId,
              periodicity: mapFundamentalPeriodicityFromDto(fundamentalPeriodicityDto, samplingFrequency),
            })
          ),
          catchApiError(false, apiError => {
            return apiError.httpError.status == 404
              ? DataValidationActions.signalFundamentalPeriodicityTestNotFound({ signalId })
              : DataValidationActions.signalFundamentalPeriodicityTestFetchFailed({ signalId });
          })
        )
      )
    );
  });
}
