import {
  ComputationStepProgress,
  stringToComputationStepProgress,
} from '@features/signal-report/shared/interface/computation-step-progress';
import { LoadingState } from '@shared/interfaces/loading-state';

import { Cycles, CyclesDto, Peak } from '../interface/cycles';
import { pfaToPmc } from './pfa-to-pmc-mapping';

export function mapCyclesFromDto(dto: CyclesDto): Cycles {
  const cycles: Cycles = {
    progress: stringToComputationStepProgress(dto.progress),
    loadingState: LoadingState.Loaded,
    data: undefined,
  };

  if (cycles.progress === ComputationStepProgress.Completed && dto.data) {
    cycles.data = dto.data.map((cycle, index) => ({
      index,
      ...cycle,
      spectrum: {
        ...cycle.spectrum,
        amplitudes: {
          contentPath: cycle.spectrum.amplitudesContentPath,
          loadingState: LoadingState.Unknown,
        },
      },
      noise: {
        ...cycle.noise,
        amplitudes: {
          contentPath: cycle.noise.amplitudesContentPath,
          loadingState: LoadingState.Unknown,
        },
      },
      peaks: cycle.peaks.map(
        peak =>
          ({
            index: peak.index + 1,
            frequency: peak.frequency,
            amplitude: peak.amplitude,
            meanField: peak.meanField,
            rms: peak.rms,
            psd: peak.psd,
            type: peak.type,
            pmc: pfaToPmc(peak.pfa),
            localSNR: peak.localSNR,
            relativeBandWidth3dBRatio: peak.relativeBandWidth3dB,
            totalQuadraticError: peak.totalQuadraticError,
            quadraticError3dB: peak.quadraticError3dB,
          }) as Peak
      ),
    }));
  }

  return cycles;
}
