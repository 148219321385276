import { BaseSelectors } from '@app-types/extra-selectors-factory';
import { AStrionSignalId } from '@features/signals/shared/interface/astrion-signal.interface';
import { createSelector } from '@ngrx/store';

import { SensorId } from '../interface/sensor-id.interface';
import { name, SensorSignalsState } from './sensor-signals.state';

export const extraSelectors = ({ selectSignals }: BaseSelectors<typeof name, SensorSignalsState>) => ({
  selectContainsSignal: (signalId: AStrionSignalId, sensorId: SensorId) =>
    createSelector(selectSignals, signals => signals.some(s => s.id === signalId && s.folderId == sensorId)),
  selectDuplicateDateSignalId: createSelector(selectSignals, signals => {
    return signals
      .filter(signal => !!signal.date)
      .map(signal => ({ id: signal.id, dateTime: signal.date?.getTime() ?? 0, createTime: signal.createdAt.getTime() }))
      .sort((a, b) => (a.dateTime === b.dateTime ? a.createTime - b.createTime : a.dateTime - b.dateTime))
      .filter((signal, index, sorted) => index > 0 && sorted[index - 1].dateTime === signal.dateTime)
      .map(s => s.id);
  }),
});
