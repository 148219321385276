import { DataType, makeBinaryField } from '@astrion-webtools/graph';
import { ASTRION_INDEXEDDB_NAME, ASTRION_INDEXEDDB_TABLES } from '@shared/constants/astrion-indexeddb';

export const samplingTestCriterionBytesStorageDescriptor = (id: string, samplingFrequency: number) => {
  return makeBinaryField({
    id,
    dbName: ASTRION_INDEXEDDB_NAME,
    storeName: ASTRION_INDEXEDDB_TABLES.samplingTests,
    dstName: 'data',
    dataType: DataType.Float64,
    additionalFields: {
      xMin: 0.0,
      xMax: samplingFrequency / 2.0,
    },
  });
};
