import { Injectable } from '@angular/core';
import { HttpWrapperService } from '@astrion-webtools/graph';
import environment from '@environments/environment.json';
import { AStrionSignalId } from '@features/signals/shared/interface/astrion-signal.interface';
import { Observable } from 'rxjs';

import { CyclesDto } from '../interface/cycles';
import { FusionDto } from '../interface/fusion';
import { PeakIdentificationDto } from '../interface/peak-identification';

@Injectable({
  providedIn: 'root',
})
export class PeakIdentificationApiService {
  constructor(private api: HttpWrapperService) {}

  getPeakIdentification(signalId: AStrionSignalId): Observable<PeakIdentificationDto> {
    return this.api.get<PeakIdentificationDto>(
      `${environment.BASE_API_URL}/reports/signal-reports/peak-identification/${signalId}`
    );
  }

  getCyclesData(signalId: AStrionSignalId): Observable<CyclesDto> {
    return this.api.get<CyclesDto>(
      `${environment.BASE_API_URL}/reports/signal-reports/peak-identification/cycles/${signalId}`
    );
  }

  getFusionData(signalId: AStrionSignalId): Observable<FusionDto> {
    return this.api.get<FusionDto>(
      `${environment.BASE_API_URL}/reports/signal-reports/peak-identification/fusion/${signalId}`
    );
  }
}
